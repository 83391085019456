* {
  box-sizing: border-box;
}

.App {
  text-align: center;
}

h1,
h2,
h3,
h4 {
  font-family: "Times New Roman", "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: normal;
  font-size: 56px;
  letter-spacing: 4px;
}

h1 {
  margin-bottom: 16px;
}

h2 {
  font-size: 32px;
  letter-spacing: 0px;
}

h3 {
  font-size: 24px;
  letter-spacing: 0px;
}

p {
  font-family: "Times New Roman", "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: normal;
}

.headerGroup {
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  max-width: 1024px;
  margin: 0 auto;
}

.navbar {
  margin: 16px;
}

.NavButton {
  border: none;
  background-color: transparent;
  margin: 8px 16px;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  cursor: pointer;
  ;
}

.Selected {
  border-bottom: 1px solid black;
}

.image {
  max-width: 800px;
  
}