.textBlock {
    padding: 8px 32px;
    margin: 16px;
    background-color: white;
    border-radius: 5px;
}

.eventBox {
    display: flex;
    background-color: white;
    margin: 0px -32px;
    padding: 32px;
}


.eventBox:nth-child(even) {
    flex-direction: row-reverse;
    background-color: #f9f9f9;
}

.eventBox.last {
    margin: 0 -32px -8px;
    border-radius: 5px;
}

/* .textBox {
    display: flex;
    justify-content: center;
    flex-direction: column;
} */

.textBox {
    padding: 0 16px;
    margin: 0 auto;
}

.imageHistory {
    overflow: auto;
    max-width: 400px;
    width: 100%;
    height: auto;
}

@media (max-width: 768px) {
    .eventBox {
        flex-direction: column;
    }

    .eventBox:nth-child(even) {
        flex-direction: column;
    }
}