.allignedParagraph {
    text-align: left;
    display: inline-block;
}

.imgBox {
    max-width: 800px;
    min-width: 200px;
    margin: 0 auto;
}

.image {
    width: 100%;
    height: auto;
}